export default {
  requiredField: function(rule, value, callback) {
    if (!value) {
      callback(new Error($tt("Це поле є необхідним")));
    } else {
      callback();
    }
  },
  minLengthField: function(rule, value, callback) {
    if (!value || value.length < rule.minLength) {
      callback(new Error($tt("Має бути не менше {minLength} символів", { minLength: rule.minLength })));
    } else {
      callback();
    }
  },
};
