<template>
  <div class="login">
    <el-card id="loginCard" v-show="!isLogined">
      <div class="logo"></div>
      <el-form class="login-form" :model="model" :rules="rules" ref="form" @submit.native.prevent="login">
        <el-form-item prop="username" :label="$t('Логін')">
          <el-input ref="login" v-model="model.username" clearable @keyup.native.enter="nextFocus"></el-input>
        </el-form-item>
        <el-form-item prop="password" :label="$t('Пароль')">
          <el-input ref="password" v-model="model.password" type="password" show-password clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="login-button" native-type="submit" type="success" block>{{ $t("Увійти") }}</el-button>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="16">
            <a class="forgot-password" href="#">{{ $t("Забули логін або пароль?") }}</a>
          </el-col>
          <div class="push"></div>
          <el-col :span="2">
            <a class="forgot-password" href="#" @click.prevent="changeLocale($event, 'ua')" :class="{ active: activeLocale == 'ua' }">ua</a>
          </el-col>
          <el-col :span="2">
            <a class="forgot-password" href="#" @click.prevent="changeLocale($event, 'en')" :class="{ active: activeLocale == 'en' }">en</a>
          </el-col>
          <!-- <el-col :span="2">
            <a class="forgot-password" href="#" @click.prevent="changeLocale($event, 'ru')" :class="{ active: activeLocale == 'ru' }">ru</a>
          </el-col> -->
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import val from "@/components/Validators.js";

export default {
  name: "Login",
  data() {
    return {
      model: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            validator: val.requiredField,
            trigger: ["blur", "change"],
          },
          {
            minLength: 4,
            validator: val.minLengthField,
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            validator: val.requiredField,
            trigger: ["blur", "change"],
          },
          {
            minLength: 4,
            validator: val.minLengthField,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    activeLocale() {
      return this.$store.getters["main/locale"];
    },
    isLogined() {
      return this.$store.getters["main/isLoggedIn"](); // && this.$store.getters["main/isNeedToLoadUserProfile"];
    },
  },
  mounted() {
    this.$refs.login.focus();
  },
  methods: {
    nextFocus(event) {
      this.$refs.password.focus();
    },
    wait(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },
    changeLocale(event, locale) {
      if (this.$store.getters["main/locale"] != locale) {
        this.$refs.form.resetFields();
        this.$store.dispatch("main/updateLocale", locale);
        this.$message($tt("Локаль оновлено!"));
      }
    },

    login() {
      this.$refs.form
        .validate()
        .then((res) => {
          this.$store
            .dispatch("main/login", {
              login: this.model.username,
              password: this.model.password,
              router: this.$router,
            })
            .finally(() => {})
            .then((data) => {
              setTimeout(() => {
                if (this.$store.getters["main/corp"]()) {
                  this.$router.push("/");
                } else {
                  this.$router.push("/corp");
                }
              }, 50);
            })
            .catch((err) => {
              globalErrorMessage($tt("Помилка автентифікації"), err);
              this.$store.dispatch("logout");
            });
        })
        .catch((err) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button {
  width: 100%;
  height: 48px;
  margin-top: 20px !important;
}
.login-form {
  width: 308px;
}
.forgot-password {
  margin-top: 10px;
  align-self: auto;
}

.footer-left {
  align-items: flex-start;
}
.login .el-card {
  width: 342px;
  height: 433px;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
}

.login .logo {
  height: 92px;
  width: 308px;
  padding: 20px 0;
}
</style>

<style lang="scss">
$confirm: #535950;

.el-button--primary {
  background: $confirm;
  border-color: $confirm;

  &:hover,
  &.active,
  &:focus {
    background: lighten($confirm, 7);
    border-color: lighten($confirm, 7);
  }
}
.login .el-input__inner:hover {
  border-color: $confirm;
}
.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: 40px;
  left: 1px;
  top: 1px;
  border-radius: 3px;
  .el-input__icon {
    width: 30px;
  }
}

.login .el-input__inner {
  height: 32px;
  line-height: 32px;
  padding: 10px;
}
.login .el-form-item__label {
  line-height: 30px;
}
.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}
h2 {
  font-family: "Open Sans";
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
}
a {
  color: #535950;
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    color: lighten($confirm, 10);
  }
}
a.active {
  font-weight: 700;
  text-decoration: none;
}
</style>
